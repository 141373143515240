import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import CardWidget from "../../widgets/cardWidget"
import ScrollBar from "../../scrollBar"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& h3": {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: "100%",
      "@media (max-width: 1025px)": {
        fontSize: 28,
      },
      "@media (max-width: 767px)": {
        fontSize: 20,
      },
    },
  },
  brand: {
    width: 140,
    marginRight: 12,
    overflow: "hidden",
  },
  title: {
    width: "100%",
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

/**
 * Слайдер популярные бренды
 * @module src/components/mainPage/popular
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 */
export default function Popular(props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span
          style={{
            fontSize: 36,
            fontWeight: 700,
            lineHeight: "100%",
          }}
        >
          Популярные бренды
        </span>
      </div>
      <div className={classes.brands}>
        <ScrollBar buttonNext fullScreen>
          {props.data.allPrismicBrand.edges.map((brand, i) => (
            <Grid className={classes.brand} key={`brand-container-${i}`}>
              <CardWidget
                variant={"brand_mainPage"}
                key={`brand ${i}`}
                cardImage={
                  brand.node.data.body[0]?.primary?.image?.localFile
                    ?.childImageSharp?.gatsbyImageData
                }
                cardTitle={brand.node.data.name}
                cardLink={`/catalog/?Производитель=["${brand.node.data.name.replace(
                  "ё",
                  "е"
                )}"]`}
              />
            </Grid>
          ))}
        </ScrollBar>
      </div>
    </div>
  )
}
